import React, { useState, useEffect, useRef } from "react";
import FeaturesGrid from '../Components/FeauturesGrid.tsx'; // Adjust the path based on your folder structure
import Banner from '../Components/Banner.tsx';
import GenericButton from "../Components/GenericButton.tsx";


import basketballImage from "../Media/basketball_nn.png";
import racingImage from "../Media/bicycle_race_nn.avif";

import { SiRedux } from "react-icons/si"; // Redux
import { FaAws } from "react-icons/fa"; // Amazon Web Services (AWS)
import { SiFirebase } from "react-icons/si"; // Firebase
import { SiNextdotjs } from "react-icons/si"; // Next.js
import { DiGit } from "react-icons/di"; // Git
import { SiJenkins } from "react-icons/si"; // Jenkins
import { SiLinux } from "react-icons/si"; // Linux
import { SiWebpack } from "react-icons/si"; // Webpack
import { BiLogoJava } from "react-icons/bi";
import { SiKibana } from "react-icons/si"; // Kibana
import { SiApollographql } from "react-icons/si"; // Apollo GraphQL
import { SiElasticsearch } from "react-icons/si"; // ElasticSearch
import { IoLogoPython } from "react-icons/io";
import { TbSql } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { BiLogoReact } from "react-icons/bi";
import { BsMicrosoft } from "react-icons/bs";
import { FaChrome } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { AiFillHtml5 } from "react-icons/ai";
import { BiLogoCss3 } from "react-icons/bi";
import { TbBrandNpm } from "react-icons/tb";
import { SiMysql } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";
import { DiDocker } from "react-icons/di";
import { SiKubernetes } from "react-icons/si";
import { GrGraphQl } from "react-icons/gr";
import { SiRabbitmq } from "react-icons/si"; // RabbitMQ
import { FiMoreHorizontal } from "react-icons/fi"; // From Feather Icons
import { SiMongodb } from "react-icons/si";

import "./About.css";


const icons = [
  BiLogoJava,
  IoLogoPython,
  TbSql,
  SiJavascript,
  BiLogoReact,
  SiRabbitmq,
  BsMicrosoft,
  FaChrome,
  BiLogoPostgresql,
  AiFillHtml5,
  BiLogoCss3,
  TbBrandNpm,
  SiMysql,
  SiTypescript,
  FaNodeJs,
  SiKibana,
  SiApollographql,
  SiElasticsearch,
  DiDocker,
  SiKubernetes,
  GrGraphQl,
  SiMongodb,
  SiRedux,
  FaAws,
  SiFirebase,
  SiNextdotjs,
  DiGit,
  SiJenkins,
  SiLinux,
  SiWebpack,
  FiMoreHorizontal
];

export default function Menu() {
  const [visibleIcons, setVisibleIcons] = useState(0); // Track how many icons are visible
  const containerRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const containerTop = containerRef.current.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      if (containerTop <= viewportHeight) {
        // Calculate how many icons should be visible
        const scrolledDistance = Math.abs(containerTop - viewportHeight);
        const iconsToShow = Math.min(Math.floor(scrolledDistance / 14), icons.length); // 1 icon every 3px
        setVisibleIcons(iconsToShow);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="home">
      <div className="page-content">
        <Banner
          image={racingImage}
          textTop="An der Spitze"
          textBottom="mit Lösungen von NextNode"
          textColor="#ffffff"
        />

        <section className="it-partner-section">
          <div className="spacer"></div>
          <h1>NextNode – Ihr Partner für digitale Lösungen</h1>
          <hr></hr>
          <p>
          NextNode steht für maß&shy;geschneiderte, zukunfts&shy;sichere IT-Lösungen, die Unter&shy;nehmen helfen, Prozesse zu optimieren, Daten effizient zu nutzen und digitale Chancen voll aus&shy;zuschöpfen.  
Ob Microsoft Dynamics 365, Web&shy;entwicklung, Google Cloud, KI oder Prozess&shy;digitalisierung – wir bieten umfassende Expertise für Ihre digitalen Heraus&shy;forderungen.  
Mit durch&shy;dachten Lösungen, naht&shy;loser Integration und praxis&shy;orientierter Umsetzung begleiten wir Sie auf dem Weg zu einer leistungs&shy;starken, vernetzten IT-Landschaft.            </p>
          <div className="spacer"></div>
          <h1>Warum mit NextNode?</h1>
          <hr></hr>

        </section>

        <FeaturesGrid />
        <Banner
          image={basketballImage}
          textTop="Sie Punkten"
          textBottom="mit unserem Know-How"
          textColor="#ffffff"
        />

        <div className="spacer"></div>

        <div ref={containerRef} className="skillset-container">
          <div className="skillset">
            {icons.map((Icon, index) => (
              <Icon
                key={index}
                className="react-icon"
                size={40}
                color="#000000"
                style={{
                  opacity: index < visibleIcons ? 1 : 0,
                  transform: index < visibleIcons ? "translateY(0px)" : "translateY(20px)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                }}
              />
            ))}
          </div>

          <p>
            Unter&shy;nehmen stehen vor der Heraus&shy;forderung, ineffi&shy;ziente Prozesse, begrenzte Skalier&shy;barkeit und mangelnde Inte&shy;gration zwischen Systemen zu über&shy;winden.
            Wir entwickeln mass&shy;geschneiderte Lösungen, die genau diese Probleme adressieren – mit auto&shy;matisierten Work&shy;flows, intel&shy;ligenten Schnitt&shy;stellen und skalier&shy;baren Archi&shy;tekturen, denn eine optimierte digitale Prozesslandschaft steigert nicht nur Ihre Effizienz, sondern befähigt auch, Entscheidungen auf der Grundlage von Daten zu treffen. 

          </p>
          <div>
            <GenericButton text="Zu den Dienstleistungen" route="/service" />
          </div>
          <div className="spacer-div-30px"></div>
        </div>
      </div>
    </div>
  );
}