import React, { useEffect } from "react";
import databaseImage from "../Media/service7_database_banner.jpg";
import modelingImage from "../Media/service7_database_modeling.jpg";
import scalabilityImage from "../Media/service7_database_scalability.jpg";
import managementImage from "../Media/service7_database_management.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function Databases() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stops observing after first appearance
          }
        });
      },
      { threshold: 0.2 }
    );
    

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={databaseImage}
          textTop="Datenbanken & Datenmodelle"
          textBottom="Strukturierte, skalierbare und leistungsfähige Datenlösungen"
          textColor="#ffffff"
        />

<section className="it-partner-section">
<div className="spacer-div-30px"></div>
  <h1>Moderne&nbsp;Datenbank&shy;lösungen für Ihr Unternehmen</h1>
  <p>
    Die Basis für daten&shy;getriebene Ent&shy;scheidungen liegt in einer hoch&shy;qualitativen Daten&shy;grundlage sowie gut durch&shy;dachten und sauber struk&shy;turierten Daten&shy;modellen. Eine effiziente Daten&shy;architektur fördert nicht nur die reibungs&shy;lose Nutzung, sondern trägt auch zu einer 
    verbesserten Performance, Skalier&shy;barkeit und Wartungs&shy;freundlichkeit Ihrer IT-Systeme bei. Unser Expertenteam ent&shy;wickelt mass&shy;geschneiderte und skalier&shy;bare Lösungen, die sicher&shy;stellen, dass Ihre 
    Unternehmens&shy;daten stets optimal struktur&shy;iert, effizient abruf&shy;bar und hoch&shy;verfügbar bleiben. 
  </p>
  <hr></hr>


  <ul className="services-list">
    <li>
      <div className="list-title">
        <strong>Daten&shy;modellierung & Architektur - Optimale Daten&shy;strukturen für maximale Effizienz</strong>
      </div>
      <ProgressiveImageCustom src={modelingImage} />
      <div className="list-description">
        <p>
          Die Grund&shy;lage jeder leistungs&shy;fähigen und stabilen Daten&shy;bank bildet ein intelligentes und durch&shy;dachtes Daten&shy;modell. Wir ent&shy;werfen und opti&shy;mieren sowohl relationale als auch NoSQL-Daten&shy;banken, 
          die exakt auf Ihre spezifischen Ge&shy;schäfts&shy;prozesse und Anforderungen zuge&shy;schnitten sind. Dabei berück&shy;sichtigen wir Best-Practices zur Normalisierung, Index&shy;strukturierung und effizienten Query-Optimierung, 
          um eine heraus&shy;ragende Performance und lang&shy;fristige Skalier&shy;barkeit zu gewähr&shy;leisten. Eine durch&shy;dachte Architektur minimiert Redundanzen, reduziert Speicher&shy;bedarf und verbessert die Integrität 
          sowie Nach&shy;haltigkeit Ihrer Daten&shy;bestände erheblich.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Skalier&shy;barkeit & Performance - Leistungs&shy;fähige Daten&shy;banken für unbe&shy;grenztes Wachstum</strong>
      </div>
      <ProgressiveImageCustom src={scalabilityImage} />
      <div className="list-description">
        <p>
          Egal, ob Sie Cloud-basierte Daten&shy;banken oder On-Premise-Lösungen be&shy;vor&shy;zugen – wir ent&shy;wickeln und opti&shy;mieren Ihre Daten&shy;bank&shy;infra&shy;struktur so, dass sie selbst höchsten Leistungs&shy;anforderungen gerecht wird. 
          wir imple&shy;mentieren modernste Indizierungs&shy;techniken, performante Caching-Mechanismen sowie effi&shy;ziente Load-Balancing-Strategien, um sicher&shy;zustellen, dass Ihre Applikationen auch unter extrem 
          hoher Last reibungs&shy;los und verzögerungs&shy;frei funktio&shy;nieren. Durch den Einsatz von Auto&shy;scaling-Methoden und horizontaler Skalierung sind Ihre Daten&shy;banken perfekt auf zukünftige Anfor&shy;derungen vorbereitet.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Datenbank&shy;management & Wartung - Sicher&shy;heit, Zuverlässigkeit und opti&shy;mierte Prozesse</strong>
      </div>
      <ProgressiveImageCustom src={managementImage} />
      <div className="list-description">
        <p>
          Ein effizientes und zuverlässiges Daten&shy;bank&shy;management ist essentiell, um Ihre kritischen Ge&shy;schäfts&shy;prozesse dauerhaft stabil und performant zu halten. Unsere Dienst&shy;leistungen umfassen 
          proaktive Über&shy;wachung, automatische Backups, Sicherheits&shy;updates sowie regel&shy;mässige Performance&shy;analysen und Optimierungen. Darüber hinaus setzen wir Mass&shy;nahmen zur Daten&shy;wieder&shy;herstellung um, 
          um im Falle eines System&shy;ausfalls einen schnellen und naht&shy;losen Wieder&shy;anlauf zu gewähr&shy;leisten. 
          Unsere umfassenden Wartungs&shy;services reduzieren Ausfall&shy;zeiten und minimieren Sicherheits&shy;lücken, damit Sie sich voll und ganz auf Ihr Kern&shy;geschäft konzentrieren können.
        </p>
      </div>
    </li>
  </ul>
</section>


        <button
           className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Datenbanken & Datenmodelle")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
