import React, { useEffect } from "react";
import aiImage from "../Media/service5_ai_banner.jpg";
import automationImage from "../Media/service5_ai_automation.jpg";
import analyticsImage from "../Media/service5_ai_analytics.jpg";
import optimizationImage from "../Media/service5_ai_optimization.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function ArtificialIntelligence() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stops observing after first appearance
          }
        });
      },
      { threshold: 0.2 }
    );
    

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={aiImage}
          textTop="Künstliche Intelligenz"
          textBottom="Entdecken Sie die Zukunft mit massgeschneiderten KI-Lösungen."
          textColor="#ffffff"
        />
<section className="it-partner-section">
<div className="spacer-div-30px"></div>
  <h1>Innovative KI-L&shy;ösungen für eine intelligente Zukunft</h1>
  <p>
    Künstliche Intelli&shy;genz revolutioniert Geschäfts&shy;prozesse und schafft völlig neue Möglich&shy;keiten. 
    Von auto&shy;matisierten Workflows über fort&shy;schrittliche Daten&shy;analysen bis hin zu interaktiven Chatbots 
    – wir entwickeln KI-Lösungen, die Ihre Effizienz steigern, Ihre Kunden&shy;kommunikation optimieren und Ihr 
    Unternehmen in die digitale Zukunft führen.
  </p>
  <hr></hr>

  <ul className="services-list">
  <li>
    <div className="list-title">
      <strong>Intelligente Prozess&shy;automatisierung – Effizienz&shy;steigerung durch KI-gestützte Workflows</strong>
    </div>
    <ProgressiveImageCustom src={automationImage} />
    <div className="list-description">
      <p>
        Durch den Einsatz von Robotic Process Auto&shy;mation (RPA) und Machine Learning lassen sich re&shy;pe&shy;titive, regel&shy;basierte Aufgaben zu&shy;ver&shy;läs&shy;sig auto&shy;matisieren. Dies reduziert manuelle Auf&shy;wände, mini&shy;miert Fehler&shy;quellen und sorgt für kon&shy;sis&shy;tente Ab&shy;läufe. Unter&shy;nehmen pro&shy;fi&shy;tieren von einer deutlich ge&shy;stei&shy;gerten Prozess&shy;ge&shy;schwin&shy;dig&shy;keit, höherer Ska&shy;lier&shy;bar&shy;keit und einer ver&shy;bes&shy;serten ope&shy;rativen Effizienz.
      </p>
    </div>
  </li>

  <li>
    <div className="list-title">
      <strong>KI-gestützte Chat&shy;bots & Sprach&shy;assis&shy;ten&shy;ten – Auto&shy;matisierte Kommu&shy;nikation auf hohem Niveau</strong>
    </div>
    <div className="list-description">
      <p>
        Moderne Conversational-AI-Systeme ermög&shy;lichen eine na&shy;tür&shy;liche, kon&shy;text&shy;basierte Inter&shy;aktion mit Kunden über Text- und Sprach&shy;schnitt&shy;stellen. Chat&shy;bots und Sprach&shy;assis&shy;ten&shy;ten be&shy;ant&shy;wor&shy;ten An&shy;fra&shy;gen in Echt&shy;zeit, leiten kom&shy;plexere An&shy;liegen effi&shy;zient weiter und ver&shy;bes&shy;sern die Er&shy;reich&shy;barkeit sowie Qua&shy;li&shy;tät des Kunden&shy;services – bei gleich&shy;zeitiger Ent&shy;lastung interner Teams.
      </p>
    </div>
  </li>

  <li>
    <div className="list-title">
      <strong>Bild- & Video&shy;analyse – Visuelle Daten intelligent aus&shy;werten</strong>
    </div>
    <div className="list-description">
      <p>
        Mit computer&shy;basierten Vision-Techno&shy;logien lassen sich visuelle Inhalte auto&shy;matisiert ana&shy;lysieren. Ob Objekt&shy;erken&shy;nung, Gesichts&shy;analyse, Bewe&shy;gungs&shy;muster oder Quali&shy;täts&shy;siche&shy;rung in der Fer&shy;tigung – KI-basierte Bild- und Video&shy;analyse ermög&shy;licht eine tief&shy;grei&shy;fende Aus&shy;wertung vis&shy;ueller Daten in Echt&shy;zeit. So entstehen neue Poten&shy;ziale in Be&shy;reichen wie Sicher&shy;heit, Pro&shy;duktion oder Customer Ex&shy;perience.
      </p>
    </div>
  </li>

  <li>
    <div className="list-title">
      <strong>Automatische Sprach- & Text&shy;ver&shy;ar&shy;bei&shy;tung – Struktur in un&shy;struk&shy;turierte Daten bringen</strong>
    </div>
    <div className="list-description">
      <p>
        Natural Language Processing (NLP) und Sprach&shy;erken&shy;nungs&shy;techno&shy;logien er&shy;schließen Text- und Sprach&shy;daten aus ver&shy;schie&shy;dens&shy;ten Quellen – von Doku&shy;menten über Kunden&shy;an&shy;fragen bis hin zu Audio&shy;dateien. Die auto&shy;matisierte Trans&shy;kription, Kate&shy;go&shy;ri&shy;sierung und Analyse großer Daten&shy;mengen unter&shy;stützt fundierte Ent&shy;schei&shy;dungen, be&shy;schleu&shy;nigt Ab&shy;läufe und ermög&shy;licht eine kon&shy;sis&shy;tente, daten&shy;ge&shy;stützte Kommu&shy;nikation.
      </p>
    </div>
  </li>

  <li>
    <div className="list-title">
      <strong>Predictive Analytics – Voraus&shy;schauend planen mit daten&shy;basierter Pro&shy;gnostik</strong>
    </div>
    <ProgressiveImageCustom src={analyticsImage} />
    <div className="list-description">
      <p>
        Durch den Einsatz fort&shy;geschrittener sta&shy;tis&shy;tischer Modelle und ma&shy;schi&shy;nellen Lernens können histo&shy;rische Daten ana&shy;lysiert und zu&shy;künftige Ent&shy;wick&shy;lungen mit hoher Genauig&shy;keit pro&shy;gnos&shy;tiziert werden. Predictive Analytics unter&shy;stützt Unter&shy;nehmen dabei, Ri&shy;siken früh&shy;zeitig zu erkennen, Chancen gezielt zu nutzen und stra&shy;te&shy;gische Ent&shy;schei&shy;dungen auf einer soli&shy;den Daten&shy;basis zu treffen.
      </p>
    </div>
  </li>

  <li>
    <div className="list-title">
      <strong>KI-gestützte Prozess&shy;opti&shy;mierung – Geschäfts&shy;abläufe daten&shy;basiert steuern</strong>
    </div>
    <ProgressiveImageCustom src={optimizationImage} />
    <div className="list-description">
      <p>
        Algo&shy;rithmen zur Prozess&shy;opti&shy;mierung ana&shy;lysieren kon&shy;ti&shy;nuier&shy;lich ope&shy;rative Ab&shy;läufe und iden&shy;ti&shy;fi&shy;zieren Ver&shy;besserungs&shy;poten&shy;ziale in Echt&shy;zeit – etwa in der Ressourcen&shy;allo&shy;kation, Preis&shy;gestaltung oder Liefer&shy;ketten&shy;logistik. Unter&shy;nehmen ge&shy;winnen dadurch ein hohes Maß an Agi&shy;lität, ver&shy;bes&shy;sern ihre Ent&shy;schei&shy;dungs&shy;qualität und sichern lang&shy;fristig ihre Wett&shy;bewerbs&shy;fähig&shy;keit im dy&shy;na&shy;mischen Markt&shy;umfeld.
      </p>
    </div>
  </li>
</ul>

</section>


        <button
              className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Künstliche Intelligenz")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
